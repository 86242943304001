import BaseModel from '@/store/models/base'
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import joinPath from 'path.join'
import uniqBy from 'lodash/uniqBy'
import { onMessage } from '@/mqtt'

export default class AgendaItem extends BaseModel {
  static entity = 'agendaitem'

  static apiPath = 'agenda_items'

  static fields() {
    return {
      business: this.attr({}),
      id: this.attr(null),
      number: this.string(''),
      number_or_business_number: this.string(''),
      title: this.string(''),
      extended_title: this.string(''),
      numbered_title: this.string(''),
      is_paragraph: this.boolean(false),
      sort_order: this.number(0),
      meeting_id: this.number(0),
      meeting: this.belongsTo('meeting', 'meeting_id'),
      attachments: this.hasMany('attachment', 'agenda_item_id'),
      proposal: this.hasOne('proposal', 'agenda_item_id'),
      proposal_id: this.attr(null),
      notes: this.morphMany('note', 'object_id', 'content_type'),
      has_annotations: this.boolean(false),
      last_seen: this.attr(null),
      personal_documents: this.hasMany('personaldocument', 'agenda_item_id'),
      bookmark: this.boolean(false),
      is_active: this.boolean(false),
      tags: this.attr([]),
    }
  }

  async setBookmark(bookmark) {
    this.bookmark = bookmark
    return await api.post(createLink(joinPath(this.idPath, 'set_bookmark')), {
      bookmark,
    })
  }

  get hasProposal() {
    return !!this.proposal_id
  }

  get breadcrumbText() {
    return this.title
  }

  async fetchSharedPersonalDocuments() {
    const { data } = await api.get(createLink(joinPath(this.idPath, 'shared_personal_documents')))
    return uniqBy(data, (a) => a.id)
  }
}

onMessage(
  'tagesordnung_item/is_active_changed',
  ({ meeting_id: meetingId, agenda_item_id: agendaItemId, is_active: isActive }) => {
    AgendaItem.update({
      where: (agendaItem) => {
        return agendaItem.meeting_id === meetingId
      },
      data: { is_active: false },
    })
    AgendaItem.update({
      where: agendaItemId,
      data: { is_active: isActive },
    })
  },
)
